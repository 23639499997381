<template>
    <b-card-code
        title="Update Router Info"
    >
        <validation-observer ref="simpleRules">
            <b-form>
                <b-row>
                    <b-col md="6">
                        <b-form-group
                            label="Route Name"
                            label-for="route-name">
                            <validation-provider
                                #default="{ errors }"
                                name="Route Name"
                                rules="required"
                            >
                                <b-form-input
                                    v-model="routerInfo.router_name"
                                    :state="errors.length > 0 ? false:null"
                                    placeholder="Enter Route Name"
                                />
                                <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                        </b-form-group>
                    </b-col>
                    <b-col md="6">
                        <b-form-group
                            label="IP Address"
                            label-for="id-address">
                            <validation-provider
                                #default="{ errors }"
                                name="IP Address"
                                rules="required"
                            >
                                <b-form-input
                                    v-model="routerInfo.ip_address"
                                    :state="errors.length > 0 ? false:null"
                                    placeholder="Enter IP Address"
                                />
                                <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                        </b-form-group>
                    </b-col>
                    <b-col md="6">
                        <b-form-group
                            label="IP Port"
                            label-for="id-port">
                            <validation-provider
                                #default="{ errors }"
                                name="Port"
                                rules="required"
                            >
                                <b-form-input
                                    v-model="routerInfo.api_port"
                                    :state="errors.length > 0 ? false:null"
                                    placeholder="Enter IP Port"
                                />
                                <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                        </b-form-group>
                    </b-col>
                    <b-col md="6">
                        <b-form-group
                            label="UserName"
                            label-for="username">
                            <validation-provider
                                #default="{ errors }"
                                name="UserName"
                                rules="required"
                            >
                                <b-form-input
                                    v-model="routerInfo.username"
                                    :state="errors.length > 0 ? false:null"
                                    placeholder="Enter Router User Name"
                                />
                                <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                        </b-form-group>
                    </b-col>
                    <b-col md="6">
                        <b-form-group
                            label="Password"
                            label-for="password">
                            <validation-provider
                                #default="{ errors }"
                                name="Password"
                                rules="required"
                            >
                                <b-form-input
                                    type="password"
                                    v-model="routerInfo.password"
                                    :state="errors.length > 0 ? false:null"
                                    placeholder="First Name"
                                />
                                <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                        </b-form-group>
                    </b-col>
                    <b-col md="6">
                        <b-form-group
                            label="Status"
                            label-for="status">
                            <validation-provider
                                #default="{ errors }"
                                name="Status"
                                rules="required"
                            >
                                <b-form-select
                                    v-model="routerInfo.status"
                                    :options="status"
                                />
                                <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                        </b-form-group>
                    </b-col>
                    <b-col cols="12">
                        <b-button
                            type="submit"
                            variant="primary"
                            @click.prevent="validationForm"
                        >
                            Submit
                        </b-button>
                    </b-col>
                </b-row>
            </b-form>
        </validation-observer>

    </b-card-code>
</template>

<script>
import BCardCode from '@core/components/b-card-code'
import {ValidationProvider, ValidationObserver} from 'vee-validate'
import {
    BFormInput, BFormGroup, BForm, BRow, BCol, BButton, BCardText, BFormSelect,
} from 'bootstrap-vue'
import {required, email} from '@validations'
import useJwt from "@/auth/jwt/useJwt";
import router from "@/router";
import { ROUTER_VIEW} from "@core/services/api";
import {checkRes} from "@core/services/helper";
import Mixin from "@core/services/mixin";

export default {
    mixins: [
        Mixin
    ],
    components: {
        BFormSelect,
        BCardCode,
        ValidationProvider,
        ValidationObserver,
        BCardText,
        BFormInput,
        BFormGroup,
        BForm,
        BRow,
        BCol,
        BButton,
    },
    data() {
        return {
            routerId: router.currentRoute.params.routerId,
            routerInfo: 'null',
            status: [
                {value: null, text: 'Please select status'},
                {value: '1', text: 'Active'},
                {value: '0', text: 'Inactive'}
            ],
            emailValue: '',
            name: '',
            required,
            email,
        }
    },
    methods: {
        getRouterData() {
            this.$http.get(process.env.VUE_APP_BASEURL + ROUTER_VIEW + this.routerId)
                .then(res => {
                    this.routerInfo = res.data.data
                }).catch(error => {
                if (error.response.status === 404) {
                    userData.value = undefined
                }
            })
        },
        validationForm() {
            this.$refs.simpleRules.validate().then(success => {
                if (success) {
                    useJwt.updateRouter(this.routerId, {
                        router_name: this.routerInfo.router_name,
                        ip_address: this.routerInfo.ip_address,
                        api_port: this.routerInfo.api_port,
                        username: this.routerInfo.username,
                        password: this.routerInfo.password,
                        status : this.routerInfo.status
                    }).then(response => {
                        if (checkRes(response.data.code)) {
                            this.$router.push({name: 'isp-router-edit'})
                                .catch(error => {
                                    this.toastMessage('success','Router Setting', response)
                                })
                        } else {
                            this.$router.push({name: 'isp-router-edit'})
                                .catch(error => {
                                    this.toastMessage('warning','Router Setting', response)
                                })
                        }
                    }).catch(error => {
                        console.log(error)
                    })
                }
            })
        },
    },
    mounted() {
        this.getRouterData()
    }
}
</script>
